import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'customer',
    meta: {
      title: '客户',
    },
    redirect: '/index',
    children: [
      {
        path: 'index',
        component:() =>  import(/* webpackChunkName: "customer" */ '@/views/customer/index.vue'),
      },
      {
        path: 'addCustomer',
        name: 'AddCustomer',
        component:() =>  import(/* webpackChunkName: "customer" */ '@/views/customer/addCustomer/index.vue'),
      },
      {
        path: 'editCustomer',
        name: 'EditCustomer',
        component:() =>  import(/* webpackChunkName: "customer" */ '@/views/customer/editCustomer.vue'),
      },
      {
        path: 'detail',
        name: 'Detail',
        component:() =>  import(/* webpackChunkName: "customer" */ '@/views/customer/detail.vue')
      },
      {
        path: 'addStepSingle',
        name: 'AddStepSingle',
        component:() =>  import(/* webpackChunkName: "customer" */ '@/views/customer/addStepSingle.vue')
      },
      {
        path: 'bindDetail',
        name: 'BindDetail',
        component:() =>  import(/* webpackChunkName: "customer" */ '@/views/customer/bindDetail.vue')
      },
/*      {
        path: '/redirect/:path(.*)',
        name: REDIRECT_NAME,
        component: () => import('/@/views/sys/redirect/index.vue'),
        meta: {
          title: REDIRECT_NAME,
          hideBreadcrumb: true,
        },
      },*/
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component:() =>  import(/* webpackChunkName: "Login" */ '@/views/login/index.vue')
  },
  {
    path: '/userInfo',
    name: 'UserInfo',
    component: () => import(/* webpackChunkName: "UserInfo" */ '@/views/userInfo/index.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
