import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import 'vant/lib/index.css';
import 'amfe-flexible'
import '@/style/base.less'
import { setupStore } from '@/store';

const app =  createApp(App)
setupStore(app)
import '@/permission'
app.use(router).mount('#app')
