/**
 * 权限路由守卫
 */
import router  from './router/index';
import {TOKEN_KEY} from "@/enums/cacheEnum";
import { showNotify } from 'vant';
const whiteList = ['/login'] // 不重定向白名单
/*router.beforeEach((to,from,next) => {

    console.log(to.path)
    if (whiteList.indexOf(to.path) !== -1) {
        next()
    } else {
        const token = localStorage.getItem(TOKEN_KEY) ;
        if (token){
            next();
        }else{
            next("/login");
            showNotify('登录状态已失效，请重新登录')
        }
    }
})*/
function resolveRoute() {
    //跳转对应的地址
    let env = process.env.NODE_ENV;
    let hostName = location.hostname;
}

/**
 * 通过meta.url判断是否与当前用户权限匹配
 * @param {*} permissions_url  接口返回的权限 [{name:'xxx'}]
 * @param {*} route  某条路由信息
 */
export const hasPermission=function(permissions_url: any[], route:any) {
    if (route.name) {
        return permissions_url.some(item => {
            return route.name == item.name
        })
    } else {
        return true
    }
}
/**
 * @description: 用户所拥有的权限AsyncRouter 路由表
 * @param {*} permissions_url  接口返回的权限 [{name:'xxx'}]
 * @param {*} asyncRouterMap  路由表所有的异步路由 []
 * @return {*}  用户所拥有的权限AsyncRouter 路由表
 */
export const filterAsyncRouter = function (permissions_url: any[], asyncRouterMap: any[]) {
    const accessedRouters = asyncRouterMap.filter(route => { //返回符合条件的路由
        if (route.meta && route.meta.noAuth) {  //不需要控制权限的添加 路由表添加noAuth:true字段
            return true;
        }
        if (hasPermission(permissions_url, route)) { //符合条件的
            if (route.children && route.children.length) {
                route.children=filterAsyncRouter(permissions_url, route.children);
            }
            return true;
        }
        return false;
    });
    return accessedRouters
}
router.onError((handler: any) => {
    console.log("error:", handler);
});
router.afterEach(() => {
})
